
































































































































































































































































































































































































































































/* eslint-disable */
import { Vue, Component, Watch } from "vue-property-decorator";
import { More } from "@/mixin/more";
import { OtherModule } from "@/store/other";
import { api_user } from "@/api";
import { Encryption } from "@/util/encryption";
import { forEach } from "ramda";
@Component
export default class extends More(api_user.get_number_list, false) {
  index = 0; //carousel index
  carousel_height = 0;
  filter = {
    subject:this.id,
    page: 1,
  };

  show = 0;
  reportTip = false;

  content = ""; //submit result
  note = ""; //submit note

  font_level = 0; // -2to0.5  -1to0.75  0to1 1to1.25 2to1.5
  new_list: string[] = [];

  options = [
    {
      title: "히스토리",
      img: require("@/assets/img/exam/history.png"),
      active_img: require("@/assets/img/exam/history-active.png"),
    },
    {
      title: "해설보기",
      img: require("@/assets/img/exam/explain.png"),
      active_img: require("@/assets/img/exam/explain-active.png"),
    },
    {
      title: "즐겨찾기",
      img: require("@/assets/img/exam/collect.png"),
      active_img: require("@/assets/img/exam/collect-active.png"),
      phone_img: require("@/assets/img/exam/phone-collect.png"),
      active_phone_img: require("@/assets/img/exam/phone-collect-active.png"),
    },
    {
      title: "메모작성",
      img: require("@/assets/img/exam/note.png"),
      active_img: require("@/assets/img/exam/note-active.png"),
      phone_img: require("@/assets/img/exam/phone-note.png"),
      active_phone_img: require("@/assets/img/exam/phone-note-active.png"),
    },
    {
      title: "정오신고",
      img: require("@/assets/img/exam/report.png"),
       phone_img: require("@/assets/img/exam/phone-report.png"),
    },
  ];

  created(){
    this.get_list()
  }


  @Watch('count')
  watch_count(e: any){
    let arr = []
    for(let i = 1; i<Math.ceil(e/20); i++){
      arr.push(api_user.get_number_list({...this.filter,page:i+1}))
    }
    Promise.all(arr).then((res)=>{
      res.forEach((item)=>{
        this.list = [...this.list,...item.results]
      })
    })
  }

  @Watch("list")
  watch_list(e: any) {
    this.new_list = e.map((item: any) => {
      return item.content;
    });
    this.note = this.list[this.index]?.notebook;
    this.content = this.list[this.index]?.result.answer;
    this.$nextTick(() => {
      this.carousel_height =
        document.getElementsByClassName("carousel-item")[
          this.index
        ]?.clientHeight;
      let exam_id = +this.$route.query.exam_id;
      if (exam_id) {
        this.$nextTick(() => {
          const index = this.list.findIndex((v: any) => v.id === exam_id);
          if (index !== -1) {
            (this.$refs["carousel"] as any).setActiveItem(index);
          }
        });
      }
    });
  }

  @Watch("index", { immediate: true })
  watch_index(e: number) {
    this.note = this.list[e]?.notebook;
    this.content = this.list[e]?.result.answer;
    this.$nextTick(() => {
      this.carousel_height =
        document.getElementsByClassName("carousel-item")[e]?.clientHeight;
    });
  }

  @Watch("font_level")
  watch_font_level() {
    this.new_list = this.list.map((item: any, index: number) => {
      return this.changeFont(index);
    });
    this.$nextTick(() => {
      this.carousel_height =
        document.getElementsByClassName("carousel-item")[
          this.index
        ]?.clientHeight;
    });
  }

  num(index:number){
    if(index<10) return '00'+index
    if(index<100) return '0'+index
    return index
  }

  toReport(){
    let index = this.index
    this.$router.push({path:`/customer/report`,query:{
      subject_title: this.list[index].subject.title,
      chapter_title: this.list[index].chapter.title,
      exam_num:this.list[index].order+''
    }});
  }

  async check(show: number) {
    let index = this.index
    if (show === 3) {
      let res: any = await api_user.post_collect(this.list[index].id);
      this.list[index].collect = res.msg;
    } else if (show === 5) {
      if(this.is_mobile){
        this.$confirm('문제풀이를 멈추고, 정오신고게시판으로 이동하시겠습니까? ',{
          confirmButtonText:'이동하기',
          cancelButtonText:'취소',
        }).then(() => {
            this.$router.push({path:`/customer/report`,query:{
              subject_title: this.list[index].subject.title,
              chapter_title: this.list[index].chapter.title,
              exam_num:this.list[index].order+''
            }});
        })
      }else{
        this.reportTip = true
      }
    } else {
      this.show = show === this.show ? 0 : show;
      if(show === 1){
        location.href="#list-bottom"
      }else if(show === 2){
        location.href="#list-bottom2"
      }
    }
  }

  async confirm(boolean: number, exam_id: number) {
    const index = this.index
    const res: any = await api_user.answer_collect_or_number({
      exam_id,
      boolean,
      answer: "",
    });
    this.list[index].result.is_bool = res.msg;
    this.list[index].result.bool_answer = boolean;
    setTimeout(()=>{ 
      this.$set(this.list[index].result,'hide',true)
    },1000)
  }

  async submit(exam_id: number) {
    if (this.content === "") return;
    const index = this.index
    let content = this.content
    const res: any = await api_user.answer_collect_or_number({
      exam_id,
      answer: content,
      boolean: 0,
    });
    this.list[index].result.is_bool = res.msg;
    this.list[index].result.answer = content;
    setTimeout(()=>{ 
      this.$set(this.list[index].result,'hide',true)
    },1000)
  }

  // to_result(){
  //   this.$confirm('아직 풀지 않은 문제가 남아 있습니다. 정말로 문제풀이를 종료하시겠습니까? ',{
  //     confirmButtonText:'종료',
  //     cancelButtonText:'취소',
  //   }).then(() => {
        
  //   })
  // }

  async submit_note() {
    let index = this.index
    let note = this.note
    await api_user.post_note({
      exam_id: this.list[index].id,
      note: note,
    });
    this.list[index].notebook = note;
    this.show = 0
    this.$message.success("저장되었습니다.");
  }

  setActiveItem(index: number) {
    (this.$refs["carousel"] as any).setActiveItem(index);
  }

  change_carousel(e: number) {
    this.index = e;
  }

  change_font_level(type: string) {
    if (
      (this.font_level === 2 && type === "plus") ||
      (this.font_level === -2 && type === "minus")
    )
      return;
    type === "plus" ? this.font_level++ : this.font_level--;
  }

  changeFont(i: number): string {
    let content = "";
    let num =
      this.font_level === -2
        ? 0.5
        : this.font_level === -1
        ? 0.75
        : this.font_level === 0
        ? 1
        : this.font_level === 1
        ? 1.25
        : 1.5;
    let b: any = this.list[i].content.split(/font-size: /gi);
    let res = b.map((item: string, index: number) => {
      if (index !== 0) {
        let left = Number(item.slice(0, 2));
        let right = item.slice(2);
        return left * num + right;
      } else {
        return item;
      }
    });
    content = res.reduce((pre: string, item: string) => {
      return pre + "font-size: " + item;
    });
    return content;
  }

  get id() {
    return Number(Encryption.base_dec(this.$route.params.id));
  }
  get is_mobile() {
    return OtherModule.is_mobile;
  }
}
